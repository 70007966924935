@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: Montserrat, system-ui, sans-serif;
  }
  
  .font-serif {
    font-family: "Instrument Serif", Georgia, serif;
  }
}

@keyframes gentleBounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-8px);
  }
}

.animate-gentle-bounce {
  animation: gentleBounce 3s ease-in-out infinite;
}
@keyframes progress-stripe {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 30px 0;
  }
}

.animate-progress-stripe {
  animation: progress-stripe 1s linear infinite;
}

.bg-stripe {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 30px 30px;
}

.font-serif {
  font-family: var(--font-instrument-serif);
}

.instrument-serif-regular {
  font-family: var(--font-instrument-serif);
  font-weight: 400;
  font-style: normal;
}

.instrument-serif-regular-italic {
  font-family: var(--font-instrument-serif);
  font-weight: 400;
  font-style: italic;
}

.font-roboto {
  font-family: var(--font-roboto);
}

.roboto-light {
  font-family: var(--font-roboto);
  font-weight: 300;
}

.roboto-regular {
  font-family: var(--font-roboto);
  font-weight: 400;
}

.roboto-medium {
  font-family: var(--font-roboto);
  font-weight: 500;
}

.roboto-bold {
  font-family: var(--font-roboto);
  font-weight: 700;
}

.font-montserrat {
  font-family: var(--font-montserrat);
}

.montserrat-light {
  font-family: var(--font-montserrat), serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}

.montserrat-regular {
  font-family: var(--font-montserrat), serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.montserrat-medium {
  font-family: var(--font-montserrat), serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

.montserrat-bold {
    font-family: var(--font-montserrat), serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

.font-lora {
  font-family: var(--font-lora);
}

.lora-regular {
  font-family: var(--font-lora);
  font-weight: 400;
  font-style: normal;
}

.lora-medium {
  font-family: var(--font-lora);
  font-weight: 500;
  font-style: normal;
}

.lora-bold {
  font-family: var(--font-lora);
  font-weight: 700;
  font-style: normal;
}

/* New styles "luxury" */
@layer base {
  :root {
    --cream: #F8F7F4;
    --taupe: #D8D4CC;
    --black: #000000;
    --background: 0 0% 100%;    /* White */
    --foreground: 0 0% 0%;      /* Black */

    --card: 33 9% 73%;          /* Taupe */
    --card-foreground: 0 0% 0%;
 
    --popover: 33 9% 73%;
    --popover-foreground: 0 0% 0%;
 
    --primary: 0 0% 0%;         /* Black */
    --primary-foreground: 0 0% 100%;
 
    --secondary: 33 9% 73%;     /* Taupe */
    --secondary-foreground: 0 0% 0%;
 
    --muted: 33 9% 73%;
    --muted-foreground: 0 0% 0%;
 
    --accent: 33 9% 73%;
    --accent-foreground: 0 0% 0%;
 
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 100%;

    --border: 33 9% 73%;
    --input: 33 9% 73%;
    --ring: 0 0% 0%;
 
    --radius: 0.5rem;
  }
}

.luxury-button {
  background-size: 200% 200%;
  transition: all 0.3s ease-in-out;
}

.luxury-button:hover {
  background-size: 200% 200%;
  animation: button-gradient 3s ease infinite;
  transform: translateY(-2px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.luxury-button-primary {
  background-image: linear-gradient(45deg, #000000, #2a2a2a, #000000);
}

.luxury-button-secondary {
  background-image: linear-gradient(45deg, var(--taupe), #e8e5e0, var(--taupe));
}

@keyframes button-gradient {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}